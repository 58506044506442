<template>
  <div class="ApplicationLibraryTenantForm-wrapper">
    <application-library-form v-bind="params" />
  </div>
</template>

<script>
import ApplicationLibraryForm from '../applicationLibraryForm/ApplicationLibraryForm'

export default {
  name: 'ApplicationLibraryTenantForm',
  components: {
    ApplicationLibraryForm
  },
  data () {
    return {
      params: {
        type: 'tenant'
      }
    }
  }
}
</script>
